export default [
  {
    key: 'increasement',
    sortable: false,
    sortField: '',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'tranId',
    sortable: false,
    sortField: '',
    label: 'field.partnerTransactionId',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'paymentSuccess',
    sortable: false,
    sortField: '',
    label: 'field.payment_status',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'totalPrice',
    sortable: false,
    sortField: '',
    label: 'field.price',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'diamond',
    sortable: false,
    sortField: '',
    label: 'field.amount_diamond',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'gameId',
    sortable: false,
    sortField: '',
    label: 'field.game_id',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'zoneId',
    sortable: false,
    sortField: '',
    label: 'field.serverId',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'createdAt',
    label: 'field.createDate',
    type: 'date',
    cols: 12,
  },
]
