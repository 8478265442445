<template>
  <div>
    <load-profile></load-profile>

    <n-search-container
      :show-header="true"
      :defaultVisible="true"
      @search="search"
      @reset="reset"
      :fields="searchFields"
    >
      <n-search-input ref="search" :fields="searchFields" v-model="params">
      </n-search-input>
    </n-search-container>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div>
        <n-table
          @sort-changed="sortChanged"
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="params.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
        >
          <template #cell(paymentSuccess)="data">
            <b-badge
              pill
              variant="danger"
              badge-glow
              v-if="data.item.paymentSuccess === 0"
            >
              Failed
            </b-badge>
            <b-badge
              pill
              variant="success"
              badge-glow
              v-else-if="data.item.paymentSuccess === 1"
            >
              Success
            </b-badge>
          </template>
        </n-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BAvatar,
  BBadge,
  BCollapse,
  BMedia,
  BMediaAside,
  BCardText,
  BCardBody,
  BCardHeader,
  BMediaBody,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Repository from '@/repositories/RepositoryFactory'
import NPagination from '@/components/NPagination'
import NSearchContainer from '@/components/NSearchContainer'
import NSearchInput from '@/components/NSearchInput'
import NButtonDelete from '@/components/NButtonDelete'
import NTable from '@/components/NTable'
import tableFields from './tableField'
import searchInputs from './searchInput'
import { v4 as uuidv4 } from 'uuid'

const PartnerTransactionRepository = Repository.get('partnerTransaction')

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    NPagination,
    NSearchContainer,
    NSearchInput,
    NButtonDelete,
    NTable,
    vSelect,
    BBadge,
    BCollapse,
    BMedia,
    BMediaAside,
    BCardText,
    BCardBody,
    BCardHeader,
    BMediaBody,
  },
  watch: {
    perPage(value) {
      this.list(1)
    },
  },
  computed: {
    accordion() {
      return this.$parent.accordion
        ? `accordion-${this.$parent.collapseID}`
        : null
    },
    perPage: {
      get() {
        return this.$store.state.pagination.perPage
      },
      set(value) {
        this.$store.commit('pagination/SET_PER_PAGE', value)
      },
    },
    activeSearch() {
      return !!this.$route.query.name || !!this.$route.query.isEnable
    },
    fields() {
      let fieldKeys = tableFields.map((item) => {
        if (item.key == 'name') {
          item.sortField = `name_${this.$i18n.locale}`
        }
        return item
      })
      return fieldKeys
    },
  },

  data() {
    this.searchFields = this.searchFields.map((field) => {
      if (field.key == 'sortPayment') {
        field.options = [
          { id: 'desc', name: this.$t('field.mostPay') },
          { id: 'asc', name: this.$t('field.leastPay') },
        ]
      }
      if (field.key == 'sortType') {
        field.options = [
          { id: 'manual', name: this.$t('field.manual') },
          { id: 'instant', name: this.$t('field.instant') },
          { id: 'pre_order', name: this.$t('field.preOrder') },
        ]
      }
      return field
    })
    return {
      items: [],
      loading: false,
      reTopUpLoading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
        name: this.$route.query.name || null,
        isEnable: this.$route.query.isEnable || null,
        limit: this.$store.state.pagination.perPage || 10,
        sortPay: null,
        sortType: null,
        type: null,
      },
      visible: false,
      collapseItemID: '',
      openOnHover: this.$parent.hover,
      loadingReport: false,
      report: {
        preOrderSum: 0,
        manualSum: 0,
        instantSum: 0,
      },
    }
  },
  mounted() {
    this.getData(this.params.page)
  },
  methods: {
    updateQuerySting() {
      this.$router.replace({ query: { tran_id: this.params.tran_id } })
    },
    list(page = 1) {
      this.params.page = page
      this.updateQuerySting()
      this.getData()
    },
    sortChanged(sortBy, sortDirection) {
      this.params.order = sortBy
      this.params.sort = sortDirection
      this.updateQuerySting()
      this.getData()
    },
    search(searchText) {
      this.params.search = searchText
      this.updateQuerySting()
      this.getData()
    },
    reset() {
      this.params.search = ''
      this.params.sortBy = null
      this.params.type = null
      this.$refs.search.reset()
      this.updateQuerySting()
      this.getData()
    },
    spliceItem(id) {
      const deleteIndex = this.items.findIndex((item) => {
        return item.id === id
      })

      this.items.splice(deleteIndex, 1)
      this.total -= 1
    },
    getData() {
      if (this.params.sortPayment) {
        this.params.sortPay = this.params.sortPayment.id
      }
      if (this.params.sortType) {
        this.params.type = this.params.sortType.id
      }
      this.loading = true
      PartnerTransactionRepository.listCheckPending(this.params.tran_id)
        .then((response) => {
          console.log(response.data)
          let data = response.data
          this.items = [...data]
          this.total = response.data.length
          this.loading = false
          this.getReport()
        })
        .catch(() => {
          this.loading = false
        })
    },

    updateVisible(val = true) {
      this.visible = val
      this.$emit('visible', val)
    },
    collapseOpen() {
      if (this.openOnHover) this.updateVisible(true)
    },
    collapseClose() {
      if (this.openOnHover) this.updateVisible(false)
    },
  },
  setup() {
    let keyFields = [...tableFields]
    const searchFields = [...searchInputs]
    return {
      keyFields,
      searchFields,
    }
  },
  created() {
    this.collapseItemID = uuidv4()
    this.visible = this.isVisible
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
